<template>
  <section
    :id="`${anchor ? anchor : 'block-' + indexBlock}`"
    :style="bgImage"
    class="block-text-and-image py-44 py-lg-125"
    :class="`bg-${getTheme(componentData?.content_fifty_fifty_full_theme)}`"
  >
    <div
      class="row"
      :class="componentData.content_fifty_fifty_full_image_positioning == 'right' ? 'row-reverse' : ''"
    >
      <div
        class="col-12 col-lg-6 position-relative block-img"
        :class="componentData.content_fifty_fifty_full_image_positioning != 'right' ? 'pe-lg-125 pe-5' : 'ps-lg-125 ps-5'"
      >
        <NuxtImg 
          v-if="componentData.content_fifty_fifty_full_image_positioning != 'background'"
          loading="lazy"
					:src="getImageDefault[0]"
					:width="getImageDefault[1]"
					:height="getImageDefault[2]"
          class="w-100 h-auto" 
          :alt="getImageTitle"
					:size="imageSizes(componentData?.content_fifty_fifty_full_image)"
        />
      </div>
      <div
        class="col-12 col-lg-6 mt-5 mt-lg-0 d-flex px-44"
        :class="componentData.content_fifty_fifty_full_image_positioning == 'right' ? 'ps-lg-125 pe-lg-125' : 'pe-lg-125'"
      >
        <div class="text-part m-auto">
          <h3
						v-if="componentData.content_fifty_fifty_full_context"
						:class="`text-${getTextColor(componentData?.content_fifty_fifty_full_theme)} h4`"
						v-html="componentData.content_fifty_fifty_full_context"
          >
          </h3>

          <h4 
            v-if="componentData.content_fifty_fifty_full_title" 
            :class="`text-${getTextColor(componentData?.content_fifty_fifty_full_theme)} h2`"
            v-html="componentData.content_fifty_fifty_full_title"
          >
          </h4>

          <div 
            v-if="componentData.content_fifty_fifty_full_text" 
            :class="`text-${getTextColor(componentData?.content_fifty_fifty_full_theme)}`"
            v-html="componentData.content_fifty_fifty_full_text"
          >
          </div>

          <ButtonCta
            v-if="componentData?.content_fifty_fifty_full_add_cta == 1"
            :target="componentData?.content_fifty_fifty_full_cta_target"
            :href="componentData?.content_fifty_fifty_full_cta_page_link? componentData?.content_fifty_fifty_full_cta_page_link : ''"
            :file="componentData?.content_fifty_fifty_full_cta_file? componentData?.content_fifty_fifty_full_cta_file : ''"
            :theme="getTheme(componentData?.content_fifty_fifty_full_cta_theme)"
            :size="componentData?.content_fifty_fifty_full_cta_size"
            :label="componentData.content_fifty_fifty_full_cta_label"
            :arrow="componentData?.content_fifty_fifty_full_add_arrow"
            :class="'mt-4'"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
	import { 
		imageDefault, 
		imageTitle, 
		imageSizes
	} from '../../../utils/image.js';

	const props = defineProps({
		data: Object,
		index: Number,
	});

	// data
	const componentData = props?.data?.data || {};
	const bgImage = ref('');
	const anchor = props?.data?.anchor || '';
	const indexBlock = props?.index || '1';
	
	if (componentData.content_fifty_fifty_full_image_positioning == 'background') {
		bgImage.value = "background: linear-gradient(0deg, rgba(252, 249, 249, 0.7), rgba(252, 249, 249, 0.7)), url(" + componentData.content_fifty_fifty_full_image?.format?.['large'][0] + ") no-repeat center center/cover";
	}

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('--- fifty-fifty-full ---');
	// console.log('componentData: ', componentData);
	// console.log('imageSizes: ', imageSizes(componentData?.content_fifty_fifty_full_image));

	// ***************
	// ***************
	// computed
	const getImageDefault = computed(() => {
		const imageObject = componentData?.content_fifty_fifty_full_image;

		return imageDefault(imageObject);
	});

	const getImageTitle = computed(() => {
		const imageObject = componentData?.content_fifty_fifty_full_image;

		return imageTitle(imageObject);
	});
</script>

<style lang="scss" scoped>
.block-text-and-image {
  overflow-x: hidden;

  .did-you-know {
    top: 11rem;
    right: 3rem;
  }

  &.bg-white {
    .did-you-know {
      background: var(--light-primary);
    }
  }

  .row-reverse {
    .block-img {
      text-align: right;

      h4 {
        text-align: left;
      }
    }
  }

  &.full-image-background {
    background: center center no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      content: "";
      background: var(--light-primary);
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }

    img,
    .did-you-know {
      display: none;
    }

    .text-part {
      z-index: 3;
    }
  }
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

// min-width 992px
@media (min-width: 992px) {
	.block-text-and-image {
    .did-you-know {
      top: 1rem;
      right: 11rem;
    }
    .img-overlapped-div {
      margin-top: -80px;
    }
    .text-part {
      margin: auto;
    }
  }
}
</style>
